import { React, useEffect } from "react";
import "./App.css";
import Header from "./Header.js";
import Form from "./Form.js";

function App() {
  useEffect(() => {
    document.title = "Draft Lottery Generator";
  }, []);
  return (
    <div className="App">
      <div className="container align-items-center">
        <Header />
        <Form />
      </div>
    </div>
  );
}

export default App;
