
import logo from './main_logo.png';
function Header() {
  return (
    <div className="container-fluid bg-white p-1 border rounded-top mt-5">
        <img
          className="w-75 m-1 mb-2"
          src={logo}
        ></img>
    </div>
  );
}
export default Header;
